function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }
function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }
import { useApolloClient, useMutation } from '@apollo/client';
import { clearCartDataFromCache } from '@magento/peregrine/lib/Apollo/clearCartDataFromCache';
import { useCartContext } from '@magento/peregrine/lib/context/cart';
import { BrowserPersistence } from '@magento/peregrine/lib/util';
import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { CREATE_CART } from '../CheckoutPage/graphql/checkoutPage.gql';
import { fullPageLoadingIndicator } from '../LoadingIndicator';
var persistence = new BrowserPersistence();
var SezzleCompletePage = () => {
  var apolloClient = useApolloClient();
  var [{
    cartId
  }, {
    createCart,
    removeCart
  }] = useCartContext();
  var params = useParams();
  var history = useHistory();
  var customerUUID = params['customer-uuid'];
  var [fetchCartId] = useMutation(CREATE_CART);
  var [completeSezzleCheckout, {
    loading,
    error
  }] = useMutation(COMPLETE_SEZZLE_CHECKOUT, {
    onCompleted: function () {
      var _ref = _asyncToGenerator(function* (data) {
        persistence.removeItem('pin');
        if (persistence.getItem('checkoutLastName') && persistence.getItem('checkoutLastName') === 'nodata') {
          persistence.removeItem('checkoutLastName');
        }
        yield removeCart();
        yield clearCartDataFromCache(apolloClient).then(() => {
          createCart({
            fetchCartId
          });
          if (persistence.getItem('shippingAddress')) {
            persistence.removeItem('shippingAddress');
          }
          history.push("/success/".concat(data.completeSezzleCheckout.order_id));
        });
      });
      return function onCompleted(_x) {
        return _ref.apply(this, arguments);
      };
    }()
  });
  useEffect(() => {
    if (cartId) {
      var pin = persistence.getItem('pin');
      completeSezzleCheckout({
        variables: {
          cartId,
          customerUUID: customerUUID ? customerUUID : null,
          pin: pin ? pin : null
        }
      });
    }
  }, [cartId, completeSezzleCheckout, customerUUID]);
  if (loading) {
    return fullPageLoadingIndicator;
  }
  if (error) {
    history.push("/cart");
  }
  return fullPageLoadingIndicator;
};
export var COMPLETE_SEZZLE_CHECKOUT = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "completeSezzleCheckout"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "cartId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "customerUUID"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "pin"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      },
      "directives": []
    }],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completeSezzleCheckout"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "cart_id"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "cartId"
                }
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "customer_uuid"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "customerUUID"
                }
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pin"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "pin"
                }
              }
            }]
          }
        }],
        "directives": [],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "order_id"
            },
            "arguments": [],
            "directives": []
          }]
        }
      }]
    }
  }],
  "loc": {
    "start": 0,
    "end": 284,
    "source": {
      "body": "\n    mutation completeSezzleCheckout(\n        $cartId: String!\n        $customerUUID: String\n        $pin: String\n    ) {\n        completeSezzleCheckout(\n            input: { cart_id: $cartId, customer_uuid: $customerUUID, pin: $pin }\n        ) {\n            order_id\n        }\n    }\n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};
export default SezzleCompletePage;