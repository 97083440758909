export default class Raygun {
  constructor() {
    this.enabled = process.env.RAYGUN_ENABLED === 'true';
    this.raygun = typeof window.rg4js === "function" ? window.rg4js : null;
  }
  isEnabled() {
    return this.enabled && typeof this.raygun === "function";
  }
  sendPageView(path) {
    if (!this.isEnabled()) {
      return;
    }
    this.raygun('trackEvent', {
      type: 'pageView',
      path: path
    });
  }
  sendLogin(identifier) {
    var email = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var firstName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var fullName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    if (!this.isEnabled()) {
      return;
    }
    var data = {
      identifier: identifier,
      isAnonymous: false
    };
    if (email !== null) {
      data.email = email;
    }
    if (firstName !== null) {
      data.firstName = firstName;
    }
    if (fullName !== null) {
      data.fullName = fullName;
    }
    this.raygun('setUser', data);
  }
  sendLogout() {
    if (!this.isEnabled()) {
      return;
    }
    this.raygun('setUser', {
      isAnonymous: true
    });
  }
  sendError(error) {
    var tags = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    if (!this.isEnabled()) {
      return;
    }
    if (!(error instanceof Error)) {
      if (typeof error === 'string' || error instanceof String) {
        error = new Error(error);
      } else {
        return;
      }
    }
    this.raygun('send', {
      error,
      tags
    });
  }
  sendCartId(cartId) {
    if (!this.isEnabled()) {
      return;
    }
    if (cartId) {
      this.raygun('withCustomData', {
        cartId: cartId
      });
    }
  }
}