var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from '@apollo/client';
import { CheckoutPageFragment } from './checkoutPageFragments.gql';
import { OrderConfirmationPageFragment } from './orderConfirmationPageFragments.gql';
export var CREATE_CART = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    # This mutation will return a masked cart id. If a bearer token is provided for\n    # a logged in user it will return the cart id for that user.\n    mutation createCart {\n        cartId: createEmptyCart\n    }\n"])));
export var IS_EMAIL_AVAILABLE = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query isEmailAvailable($email: String!) {\n        isEmailAvailable(email: $email) {\n            is_email_available\n        }\n    }\n"])));
export var PLACE_ORDER = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    mutation placeOrder($cartId: String!) {\n        placeOrder(input: { cart_id: $cartId }) @connection(key: \"placeOrder\") {\n            order {\n                order_number\n            }\n        }\n    }\n"])));

// A query to fetch order details _right_ before we submit, so that we can pass
// data to the order confirmation page.
export var GET_ORDER_DETAILS = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    query getOrderDetails($cartId: String!) {\n        cart(cart_id: $cartId) {\n            id\n            ...OrderConfirmationPageFragment\n        }\n    }\n    ", "\n"])), OrderConfirmationPageFragment);
export var GET_CHECKOUT_DETAILS = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    query getCheckoutDetails($cartId: String!) {\n        cart(cart_id: $cartId) {\n            id\n            ...CheckoutPageFragment\n        }\n    }\n    ", "\n"])), CheckoutPageFragment);
export var GET_CUSTOMER = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    query GetCustomerForCheckout {\n        customer {\n            id\n            default_shipping\n            firstname\n        }\n    }\n"])));