export var emailValidation = _ref => {
  var _String;
  var {
    value,
    label
  } = _ref;
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!value || value.startsWith('-') || value.endsWith('-') || value.includes('@-') || !re.test((_String = String(value)) === null || _String === void 0 ? void 0 : _String.toLowerCase())) {
    return label;
  }
};
export var validatePassword = _ref2 => {
  var {
    value = '',
    label
  } = _ref2;
  var count = {
    lower: 0,
    upper: 0,
    digit: 0,
    special: 0
  };
  for (var char of value) {
    if (/[a-z]/.test(char)) count.lower++;else if (/[A-Z]/.test(char)) count.upper++;else if (/\d/.test(char)) count.digit++;else if (/\S/.test(char)) count.special++;
  }
  if (Object.values(count).filter(Boolean).length < 3) {
    return label;
  }
};
export var postalCodeValidation = _ref3 => {
  var _String2;
  var {
    value,
    label
  } = _ref3;
  var re = /[a-zA-Z][0-9][a-zA-Z](-| |)[0-9][a-zA-Z][0-9]/;
  if (!value) {
    return label;
  }
  if (!re.test((_String2 = String(value)) === null || _String2 === void 0 ? void 0 : _String2.toLowerCase())) {
    return label;
  }
};
export var fullNameValidation = _ref4 => {
  var {
    value,
    label
  } = _ref4;
  var re = /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/;
  if (!re.test(String(value))) {
    return label;
  }
};
export var phoneNumberValidation = _ref5 => {
  var _String3;
  var {
    value,
    label
  } = _ref5;
  var re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (!re.test((_String3 = String(value)) === null || _String3 === void 0 ? void 0 : _String3.toLowerCase())) {
    return label;
  }
};
export var noEmptyValueValidation = _ref6 => {
  var {
    value,
    label
  } = _ref6;
  if (!value) {
    return label;
  }
};
export var fourDigitValidation = _ref7 => {
  var {
    value,
    label
  } = _ref7;
  var re = /^[0-9]*$/;
  if (!re.test(String(value))) {
    return label;
  }
};
export var creditCardType = cc => {
  var amex = new RegExp('^3[47][0-9]{13}$');
  var visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
  // let cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
  // let cup2 = new RegExp('^81[0-9]{14}[0-9]*$');

  var mastercard = new RegExp('^5[1-5][0-9]{14}$');
  var mastercard2 = new RegExp('^2[2-7][0-9]{14}$');
  var disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
  var disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
  var disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

  // let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
  // let jcb =  new RegExp('^35[0-9]{14}[0-9]*$');

  if (visa.test(cc)) {
    return 'V';
  }
  if (amex.test(cc)) {
    return 'AX';
  }
  if (mastercard.test(cc) || mastercard2.test(cc)) {
    return 'M';
  }
  if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
    return 'NO';
  }
  return undefined;
};