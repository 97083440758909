// BUILDPACK: wrap-esm-loader added 3 imports
import _venia_adobe_data_layer_1 from '/opt/atlassian/pipelines/agent/build/app/pwa/packages/extensions/venia-adobe-data-layer';
import _wrapUseApp_2 from '@magento/venia-product-recommendations/lib/wrappers/wrapUseApp';
import _wrapUseApp_3 from '@magento/venia-data-collector/lib/wrappers/wrapUseApp';
import { useCallback, useEffect, useMemo, useState } from 'react';
import errorRecord from '@magento/peregrine/lib/util/createErrorRecord';
import { useAppContext } from '@magento/peregrine/lib/context/app';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_GEO_DATA } from './getGeoData.gql';
import { BrowserPersistence } from '@magento/peregrine/lib/util';
import { GET_STORE_BY_CODE, GET_STORE_BY_LAT_LNG } from './getStoreByLatLng.gql';
import { GET_ALL_BADGES } from './getAllBadges.gql';
import { GET_STORE_CONFIG } from '../Footer/footer.gql';
import { ROOT_CAT_LIST } from '@magento/venia-ui/lib/components/CategoryList/categoryList.gql';
var dismissers = new WeakMap();

// Memoize dismisser funcs to reduce re-renders from func identity change.
var getErrorDismisser = (error, onDismissError) => {
  return dismissers.has(error) ? dismissers.get(error) : dismissers.set(error, () => onDismissError(error)).get(error);
};

/**
 * Talon that handles effects for App and returns props necessary for rendering
 * the app.
 *
 * @param {Function} props.handleError callback to invoke for each error
 * @param {Function} props.handleIsOffline callback to invoke when the app goes offline
 * @param {Function} props.handleIsOnline callback to invoke wen the app goes online
 * @param {Function} props.handleHTMLUpdate callback to invoke when a HTML update is available
 * @param {Function} props.markErrorHandled callback to invoke when handling an error
 * @param {Function} props.renderError an error that occurs during rendering of the app
 * @param {Function} props.unhandledErrors errors coming from the error reducer
 *
 * @returns {{
 *  hasOverlay: boolean
 *  handleCloseDrawer: function
 * }}
 */

var persistence = new BrowserPersistence();
var useApp_4 = props => {
  var {
    handleError,
    handleIsOffline,
    handleIsOnline,
    markErrorHandled,
    renderError,
    unhandledErrors
  } = props;
  var [browserLocationValues, setBrowserLocationBrowser] = useState();
  var [{
    currentStore
  }, {
    setIpLatLng,
    setCurrentStore,
    setProductBadges,
    setStoreConfig
  }] = useAppContext();
  var {
    data
  } = useQuery(GET_STORE_BY_CODE, {
    variables: {
      code: process.env.DEFAULT_STORE_LOCATOR
    },
    fetchPolicy: 'cache-and-network'
  });
  var {
    data: dataConfig
  } = useQuery(GET_STORE_CONFIG, {
    fetchPolicy: 'cache-and-network'
  });
  var {
    loading: loadingGeo,
    data: dataGeo,
    error: errorGeo
  } = useQuery(GET_GEO_DATA, {
    fetchPolicy: 'cache-and-network'
  });
  var {
    loading: loadingBadges,
    data: dataBadges
  } = useQuery(GET_ALL_BADGES, {
    fetchPolicy: 'cache-and-network'
  });
  var [fetchStoreByLatLng, {
    data: storeByLatLngData
  }] = useLazyQuery(GET_STORE_BY_LAT_LNG, {
    fetchPolicy: 'cache-and-network'
  });
  useEffect(() => {
    if (dataBadges && !loadingBadges) {
      setProductBadges(dataBadges.getAllBadges);
    }
  }, [dataBadges, loadingBadges, setProductBadges]);
  useEffect(() => {
    if (dataConfig) {
      setStoreConfig(dataConfig.storeConfig);
    }
  }, [dataConfig]);
  useEffect(() => {
    var successFn = props => {
      //will trigger default store replacement to more precise one
      setBrowserLocationBrowser({
        lat: props.coords.latitude,
        lng: props.coords.longitude
      });
    };
    var failureFn = errorProps => {
      //nothing to do, because default store is already initialized
    };
    window.navigator.geolocation.getCurrentPosition(successFn, failureFn);
  }, [dataConfig]);
  var setLatLon = function () {
    var lat = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '45.49915000';
    var lng = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '-73.70624000';
    setIpLatLng({
      ipLat: lat,
      ipLng: lng
    });
    fetchStoreByLatLng({
      variables: {
        latitude: lat,
        longitude: lng,
        radius: 500
      }
    });
  };
  useEffect(() => {
    var _dataGeo$getGeoData;
    if (browserLocationValues) {
      setLatLon(browserLocationValues.lat, browserLocationValues.lng);
    } else if (dataGeo !== null && dataGeo !== void 0 && (_dataGeo$getGeoData = dataGeo.getGeoData) !== null && _dataGeo$getGeoData !== void 0 && (_dataGeo$getGeoData = _dataGeo$getGeoData.items) !== null && _dataGeo$getGeoData !== void 0 && _dataGeo$getGeoData.length && !loadingGeo && !errorGeo) {
      var items = dataGeo.getGeoData.items;
      var lat = items.find(item => item.key === 'Fastly-Geoip-Latitude');
      var lng = items.find(item => item.key === 'Fastly-Geoip-Longitude');
      setLatLon(lat === null || lat === void 0 ? void 0 : lat.value, lng === null || lng === void 0 ? void 0 : lng.value);
    } else if (!loadingGeo && errorGeo) {
      setLatLon(undefined, undefined);
    }
  }, [dataGeo, errorGeo, browserLocationValues]);
  var selectStore = store => {
    persistence.setItem('currentStore', store);
    setCurrentStore(store);
  };
  useEffect(() => {
    var _storeByLatLngData$st, _data$storesByCode, _storeByLatLngData$st2;
    //trying to select neares store based on current location
    if (storeByLatLngData !== null && storeByLatLngData !== void 0 && (_storeByLatLngData$st = storeByLatLngData.storesByLatLng) !== null && _storeByLatLngData$st !== void 0 && (_storeByLatLngData$st = _storeByLatLngData$st.stores) !== null && _storeByLatLngData$st !== void 0 && _storeByLatLngData$st.length) {
      var filteredStoreByLatLngData = storeByLatLngData.storesByLatLng.stores.filter(s => (s === null || s === void 0 ? void 0 : s.bopis_enabled) === "1");
      var _currentStore = filteredStoreByLatLngData ? filteredStoreByLatLngData[0] : data.storesByCode.stores[0];
      selectStore(_currentStore);
    }
    //setting default store if more precise one cannot be selected
    else if (!currentStore && data !== null && data !== void 0 && (_data$storesByCode = data.storesByCode) !== null && _data$storesByCode !== void 0 && (_data$storesByCode = _data$storesByCode.stores) !== null && _data$storesByCode !== void 0 && _data$storesByCode.length && (storeByLatLngData === null || storeByLatLngData === void 0 || (_storeByLatLngData$st2 = storeByLatLngData.storesByLatLng) === null || _storeByLatLngData$st2 === void 0 || (_storeByLatLngData$st2 = _storeByLatLngData$st2.stores) === null || _storeByLatLngData$st2 === void 0 ? void 0 : _storeByLatLngData$st2.length) === 0) {
      var _currentStore2 = data.storesByCode.stores.find(s => (s === null || s === void 0 ? void 0 : s.bopis_enabled) === "1") || data.storesByCode.stores[0];
      selectStore(_currentStore2);
    }
  }, [data, storeByLatLngData]);
  var reload = useCallback(process.env.NODE_ENV === 'development' ? () => {
    console.log('Default window.location.reload() error handler not running in developer mode.');
  } : () => {
    window.location.reload();
  }, []);
  var renderErrors = useMemo(() => renderError ? [errorRecord(renderError, window, useApp, renderError.stack)] : [], [renderError]);
  var errors = renderError ? renderErrors : unhandledErrors;
  var handleDismissError = renderError ? reload : markErrorHandled;

  // Only add toasts for errors if the errors list changes. Since `addToast`
  // and `toasts` changes each render we cannot add it as an effect dependency
  // otherwise we infinitely loop.
  useEffect(() => {
    for (var {
      error,
      id,
      loc
    } of errors) {
      handleError(error, id, loc, getErrorDismisser(error, handleDismissError));
    }
  }, [errors, handleDismissError, handleError]);
  var [appState, appApi] = useAppContext();
  var {
    closeDrawer
  } = appApi;
  var {
    hasBeenOffline,
    isOnline,
    overlay
  } = appState;
  useEffect(() => {
    if (hasBeenOffline) {
      if (isOnline) {
        handleIsOnline();
      } else {
        handleIsOffline();
      }
    }
  }, [handleIsOnline, handleIsOffline, hasBeenOffline, isOnline]);
  var handleCloseDrawer = () => {
    closeDrawer();
  };
  return {
    hasOverlay: !!overlay,
    handleCloseDrawer
  };
};
;
export var useApp = _wrapUseApp_3(_wrapUseApp_2(_venia_adobe_data_layer_1(useApp_4)));